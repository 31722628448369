<template>
  <TradeProcedureSidebar
    :title="title"
    tabs="adminsTrade">
    <template v-slot:header>
      <div class="admin__button">
        <VToggleDisplay
          v-if="isToggleDisplay"
          :class="['mr-20', classToggleDisplay]"
          @set-display="setDisplay"
        />
        <VButton mod="plus" @click="$router.push({ name: 'create-trade' })">Добавить</VButton>
      </div>
    </template>
  </TradeProcedureSidebar>
</template>

<script>
import TradeProcedureSidebar from '@/components/views/TradeProcedure/VTradeProcedureSidebar'
import VToggleDisplay from '@/components/ui/buttons/VToggleDisplay'
import VButton from '@/components/ui/buttons/VButton'
import { useStore } from 'vuex'
export default {
  components: {
    TradeProcedureSidebar,
    VToggleDisplay,
    VButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isToggleDisplay: {
      type: Boolean,
      required: false,
      default: true
    },
    classToggleDisplay: {
      type: String,
      required: false
    }
  },
  setup () {
    const store = useStore()
    const setDisplay = (value) => {
      store.commit('display/setDisplay', value)
    }

    return {
      setDisplay
    }
  }
}
</script>

<style>
.admin__button {
  display: flex;
}
</style>
