<template>
  <div class="toggle-display">
    <button
      v-for="btn in toggleButtons"
      :key="btn"
      class="toggle-display__btn"
      :class="btn.displayIsTable === isDisplayTable ? 'toggle-display__btn--active' : ''"
      @click="changeIsDisplay(btn.displayIsTable)"
    >
      <VIcon width="27" height="27" :icon-name="'ToggleDisplay' + btn.iconName" />
    </button>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
import { useStore } from 'vuex'

const emit = defineEmits(['set-display'])

const store = useStore()
const isDisplayTable = ref(store.getters['display/getIsDisplayTable'])
const toggleButtons = [
  { iconName: 'Blocks', displayIsTable: false },
  { iconName: 'Table', displayIsTable: true }
]

const changeIsDisplay = value => {
  if (isDisplayTable.value === value) return
  isDisplayTable.value = value
  store.commit('display/setIsDisplayTable', value)
  emit('set-display', value)
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.toggle-display {
  display: flex;
  gap: 20px;

  &__btn {
    border: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    color: $color-gray;
    background: transparent;
    cursor: pointer;

    &--active {
      color: $color-primary;
    }
  }
}
</style>
